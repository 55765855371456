import './list.scss';
import dataApi from '@/api/product/category.js';
/*
*活动分类
*  */
export default {
  name: 'project-category',
  components: {},
  data() {
    return {
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        ...this.$store.getters.tableConfig,
        column: [
          {
            label: '名称',
            prop: 'name',
            type: 'input',
            search: 'true',
            required: true,
            span: 24,
            rules: [
              {
                required: true,
                message: '名称不能为空'
              }
            ]
          },
          {
            type: 'tree',
            label: '上级分类',
            span: 12,
            dicUrl: `${this.$baseUrl}general/get_product_category`,
            dicMethod: 'post',
            dicFlag: true,
            prop: 'parent_id',
            dicQuery: {}
          },
          {
            type: 'select',
            label: '品牌故事',
            span: 12,
            dicUrl: `${this.$baseUrl}general/get_paction`,
            dicMethod: 'post',
            prop: 'article_id',
            dicQuery: {
              brand: '1'
            },
            hide: true
          },
          {
            label: '使用场景',
            prop: 'scenario',
            type: 'select',
            addDisplay: true,
            editDisplay: true,
            search: 'true',
            dicUrl: `${this.$baseUrl}general/get_dict`,
            dicMethod: 'post',
            dicFlag: true,
            filterable: false,
            multiple: false,
            dicQuery: {
              dict_type: 'product_category_scenario'
            },
            required: true,
            rules: [
              {
                required: true,
                message: '请选择使用场景'
              }
            ],
            span: 12
          },
          {
            label: '分类属性',
            prop: 'type',
            type: 'select',
            addDisplay: true,
            editDisplay: true,
            dicUrl: `${this.$baseUrl}general/get_dict`,
            dicMethod: 'post',
            dicFlag: true,
            filterable: false,
            multiple: false,
            dicQuery: {
              dict_type: 'product_category_type'
            },
            required: true,
            rules: [
              {
                required: true,
                message: '请选择分类属性'
              }
            ],
            span: 12
          },
          {
            label: '图片',
            prop: 'pic_url',
            type: 'upload',
            listType: 'picture-img',
            span: 12,
            tip: '只能上传jpg/png图片,且不超过100kb,建议尺寸1035*297px',
            action: `${this.$baseUrl}system/upload`,
            required: true,
            accept: 'image/png, image/jpeg',
            rules: [
              {
                required: true,
                message: '请上传图片'
              }
            ]
          },
          {
            label: '图标',
            prop: 'icon',
            type: 'upload',
            listType: 'picture-img',
            span: 12,
            tip: '只能上传jpg/png图片,且不超过5kb,建议尺寸100*100px',
            action: '/system/upload',
            required: true,
            accept: 'image/png, image/jpeg',
            rules: [
              {
                required: true,
                message: '请上传图标'
              }
            ]
          },
          {
            label: '背景色',
            prop: 'background',
            type: 'color',
            colorFormat: 'hex',
            showAlpha: false,
            required: true,
            span: 12
          },
          
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: -1
              },
              {
                label: '启用',
                value: 1
              }
            ],
            display: true,
            search: 'true',
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true,
            span: 12
          },
          {
            // 显示状态
            label: '显示状态',
            type: 'radio',
            value: 1,
            dicData: [
              {
                label: '显示',
                value: 1
              },
              {
                label: '隐藏',
                value: 0
              }
            ],
            props: {
              label: 'label',
              value: 'value'
            },
            span: 12,
            display: true,
            prop: 'is_show'
          },
          {
            // 排序
            type: 'number',
            value: 0,
            label: '排序',
            span: 12,
            display: true,
            prop: 'sorting'
          },
          {
            label: '说明',
            prop: 'remark',
            type: 'textarea',
            span: 24
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },
      searchData: {}
    };
  },
  computed: {},
  mounted() {},
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      console.log(params);
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    handleSubmit(form, done, loading) {
      console.log('form', form, loading);
      dataApi.add.r(form).then((res) => {
        console.log(res);
        done(form);
        this.getList();
        loading();
      });
      done();
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUpdate(form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update.r(form).then((res) => {
        console.log(res);
        done(form);
        this.getList();
        loading();
      });
      done();
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
